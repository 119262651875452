<template>
  <div class="">
    <div class="p-2">
      <span style="color: #5E5873">Recent</span>
      <div class="row">
        <b-card
          v-for="(product, index) in files.recent"
          :key="index"
          class="ecommerce-card m-1"
          no-body
        >
          <div
            v-if="product.type === 'Image'"
            class="image-view"
            @click="imageView(product.path)"
          >
            <div class="item-img text-center">
              <div
                style="background-color: #F8F8F8; height: 150px; opacity: 0.8"
                class="p-1"
              >
                <img
                  class="card-img-top"
                  style="height: inherit"
                  :src="product.path"
                >
              </div>
            </div>
            <b-card-body>
              <h6 class="item-name">
                <div class="d-flex justify-content-between">
                  <div class="item-rating mr-2">
                    <span>{{ product.name }}</span>
                  </div>
                  <div>
                    <h6
                      class="item-price"
                      style="color: #B9B9C3"
                    >
                      {{ product.type }}
                    </h6>
                  </div>
                </div>
              </h6>
              <b-card-text
                class="item-description"
                style="color: #B9B9C3"
              >
                {{ product.date }}
              </b-card-text>
            </b-card-body>
          </div>

          <router-link
            v-else
            :to="{name: 'view-files', params: {type: product.type}}"
          >
            <div class="item-img text-center">
              <div
                style="background-color: #F8F8F8; height: 150px;"
                class="p-1  d-flex  align-items-center justify-content-center"
              >
                <img
                  class="text-center"
                  style="height: 70px; width: 70px"
                  :src="product.type==='Excel'? 'https://cdn-icons-png.flaticon.com/512/888/888850.png' : 'https://www.iconpacks.net/icons/2/free-pdf-icon-1512-thumb.png'"
                >
              </div>
            </div>

            <!-- Product Details -->
            <b-card-body>
              <h6 class="item-name">
                <div class="d-flex justify-content-between">
                  <div class="item-rating mr-2">
                    <span>{{ product.name }}</span>
                  </div>
                  <div>
                    <h6
                      class="item-price"
                      style="color: #B9B9C3"
                    >
                      {{ product.type }}
                    </h6>
                  </div>
                </div>
              </h6>
              <b-card-text
                class="item-description"
                style="color: #B9B9C3"
              >
                {{ product.date }}
              </b-card-text>
            </b-card-body>
          </router-link>

        </b-card>

      </div>
    </div>
    <div class="p-2">
      <span style="color: #5E5873">Files</span>
      <div class="row">
        <b-card
          v-for="(product, index) in files.recent"
          :key="index"
          class="ecommerce-card m-1"
          no-body
        >
          <div
            v-if="product.type === 'Image'"
            class="image-view"
            @click="imageView(product.path)"
          >
            <div class="item-img text-center">
              <div
                style="background-color: #F8F8F8; height: 150px; opacity: 0.8"
                class="p-1"
              >
                <img
                  class="card-img-top"
                  style="height: inherit"
                  :src="product.path"
                >
              </div>
            </div>
            <b-card-body>
              <h6 class="item-name">
                <div class="d-flex justify-content-between">
                  <div class="item-rating mr-2">
                    <span>{{ product.name }}</span>
                  </div>
                  <div>
                    <h6
                      class="item-price"
                      style="color: #B9B9C3"
                    >
                      {{ product.type }}
                    </h6>
                  </div>
                </div>
              </h6>
              <b-card-text
                class="item-description"
                style="color: #B9B9C3"
              >
                {{ product.date }}
              </b-card-text>
            </b-card-body>
          </div>

          <router-link
            v-else
            :to="{name: 'view-files', params: {type: product.type}}"
          >
            <div class="item-img text-center">
              <div
                style="background-color: #F8F8F8; height: 150px;"
                class="p-1  d-flex  align-items-center justify-content-center"
              >
                <img
                  class="text-center"
                  style="height: 70px; width: 70px"
                  :src="product.type==='Excel'? 'https://cdn-icons-png.flaticon.com/512/888/888850.png' : 'https://www.iconpacks.net/icons/2/free-pdf-icon-1512-thumb.png'"
                >
              </div>
            </div>

            <!-- Product Details -->
            <b-card-body>
              <h6 class="item-name">
                <div class="d-flex justify-content-between">
                  <div class="item-rating mr-2">
                    <span>{{ product.name }}</span>
                  </div>
                  <div>
                    <h6
                      class="item-price"
                      style="color: #B9B9C3"
                    >
                      {{ product.type }}
                    </h6>
                  </div>
                </div>
              </h6>
              <b-card-text
                class="item-description"
                style="color: #B9B9C3"
              >
                {{ product.date }}
              </b-card-text>
            </b-card-body>
          </router-link>

        </b-card>

      </div>
    </div>
    <div class="p-2">
      <span style="color: #5E5873">Folder</span>
      <div class="row">
        <b-card
          v-for="(product, index) in files.folder"
          :key="index"
          class="ecommerce-card m-1"
          no-body
        >
          <router-link
            :to="{name: 'view-files', params: {type: product.type}}"
          >
            <div class="item-img text-center">
              <div style="background-color: #F8F8F8; height: 150px;">
                <!-- <img
                    class="card-img-top"
                    :src="product.img"
                  > -->
              </div>
            </div>

            <!-- Product Details -->
            <b-card-body>
              <h6 class="item-name">
                <div class="d-flex justify-content-between">
                  <div class="item-rating">
                    <span>{{ product.name }}</span>
                  </div>
                  <div>
                    <h6
                      class="item-price"
                      style="color: #B9B9C3"
                    >
                      {{ product.size }}
                    </h6>
                  </div>
                </div>
              </h6>
              <b-card-text
                class="item-description"
                style="color: #B9B9C3"
              >
                {{ product.date }}
              </b-card-text>
            </b-card-body>
          </router-link>
        </b-card>

      </div>
    </div>
  </div>
</template>

<script>

import {
  ref,
} from '@vue/composition-api'
import { BCard, BCardBody, BCardText } from 'bootstrap-vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
  },
  data() {
    return {
      files: {},
      images: [
        {
          name: "image1",
          url: "https://images.unsplash.com/photo-1515165562839-978bbcf18277?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
        },
        {
          name: "image2",
          url: "https://images.unsplash.com/photo-1527295110-5145f6b148d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1431&q=80",
        },
        {
          url: "https://images.unsplash.com/photo-1542731538-f99c9f100885?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80",
        },
      ],
    }
  },
  created() {
    this.$http.get('/documents/data')
      .then(response => {
        const files = response.data
        this.files = files
      })
    this.$imageViewer.images(this.images)
  },
  methods: {
    imageView(path) {
      const image = [{
        name: "image1",
        url: path,
      }]
      this.$imageViewer.images(image)
      this.$imageViewer.show()
    },
  },
  setup() {
    const tasks = ref([])

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // eslint-disable-next-line no-use-before-define

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      tasks,
      perfectScrollbarSettings,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
